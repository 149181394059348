.first-facility-area { 
  background-color: #233f8f; 
  position: relative; 
  left: 0; 
  bottom: 0; 
  z-index: 1; 
  border-radius: 4px 4px 0 0; 
  width: 100%; 
  padding: 50px; 
  margin-bottom: 0px;   
        
} 

*,:after,:before { 
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box; 
  box-sizing: border-box;
} 


.first-facility-area .shape  { 
  position: absolute; 
  right: 0; 
  top: 0; 
  opacity: .5; 
  z-index: -1;
} 

.row > *  { 
  flex-shrink: 0; 
  width: 100%; 
  max-width: 100%; 
  padding-right: calc(var(--bs-gutter-x)/ 2); 
  padding-left: calc(var(--bs-gutter-x)/ 2); 
  margin-top: var(--bs-gutter-y);
} 

@media (min-width: 576px){ 
.col-sm-6 { 
  width: 50%;
} 
@media (min-width: 999px) {
  .col-sm-6 {
      width: 25%;
      flex: 0 0 auto;
  }
}

.col-sm-6 { 
  -moz-box-flex: 0; 
  flex: 0 0 auto;
} 
}     

@media (min-width: 992px){ 
.col-lg-4 { 
  width: 33.3333333333%;
} 

.col-lg-4 { 
  -moz-box-flex: 0; 
  flex: 0 0 auto;
} 
}     

@media (min-width: 576px){ 
.offset-sm-3 { 
  margin-left: 25%;
} 
}     

@media (min-width: 992px){ 
.offset-lg-0 { 
  margin-left: 0;
} 
}     



@media screen and (max-width:768px){
  .first-facility-item { 
  position: relative; 
  padding-left: 0px;
} 
  .row { 
  gap:15px;
  --bs-gutter-x: 1.5rem; 
  --bs-gutter-y: 0; 
  display: -moz-box; 
  display: flex; 
  flex-wrap: wrap; 
  margin-top: calc(var(--bs-gutter-y) * -1); 
  margin-right: calc(var(--bs-gutter-x)/ -2); 
  
} 
}
@media screen and (min-width:768px){
  .first-facility-item { 
  position: relative; 
  padding-left: 0px;
} 
  .row { 
  gap:0px;
  --bs-gutter-x: 1.5rem; 
  --bs-gutter-y: 0; 
  display: -moz-box; 
  display: flex; 
  flex-wrap: wrap; 
  margin-top: calc(var(--bs-gutter-y) * -1); 
  margin-right: calc(var(--bs-gutter-x)/ -2); 
  margin-left: calc(var(--bs-gutter-x)/ -2);
} 
}






.first-facility-item img{
width: 90px;
} 


.facility-content {
display: flex;
align-items: center;
gap: 10px; 
}

.facility-icon {
width: 40px;
height: auto;
}

.first-facility-item i  { 
  border: 1px dashed var(--primary-theme); 
  background-color: #fff; 
  width: 70px; 
  height: 70px; 
  line-height: 70px; 
  font-size: 40px; 
  display: inline-block; 
  text-align: center; 
  border-radius: 50%; 
  color: var(--primary-theme); 
  position: absolute; 
  left: 0; 
  top: 0;
} 

[class*=" flaticon-"]::after, [class*=" flaticon-"]::before,[class^="flaticon-"]:after,[class^="flaticon-"]:before { 
  font-family: Flaticon; 
  font-style: normal;
} 

.flaticon-support:before { 
  content: "\f103";
} 

[class*=" flaticon-"]::after, [class*=" flaticon-"]::before, [class^="flaticon-"]::after, [class^="flaticon-"]::before { 
  font-family: Flaticon; 
  font-style: normal;
} 

h3 { 
  margin-top: 0; 
  margin-bottom: .5rem; 
  font-weight: 500; 
  line-height: 1.2;
} 

/* h3 { 
  font-size: -moz-calc(1.3rem + .6vw); 
  font-size: calc(1.3rem + .6vw);
} 

@media (min-width: 1200px){ 
h3 { 
  font-size: 1.75rem;
} 
}      */

h3 { 
  color: white; 
  font-weight: 700; 
  font-family: Roboto,sans-serif;
} 

h3 { 
  font-size: 27px;
} 

.first-facility-item h3  { 
  margin-bottom: 10px;
} 

p { 
  margin-top: 0; 
  margin-bottom: 1rem;
} 



p:last-child { 
  margin-bottom: 0;
} 

.flaticon-online-learning:before { 
  content: "\f109";
} 

.flaticon-care:before { 
  content: "\f107";
} 
  
#style-sX8cX.style-sX8cX {  
 display: block;  
  overflow: hidden;  
  position: absolute;  
  inset: 0px;  
  box-sizing: border-box;  
  margin: 0px;  
}  
#style-CEyeB.style-CEyeB {  
 position: absolute;  
  inset: 0px;  
  box-sizing: border-box;  
  padding: 0px;  
  border: none;  
  margin: auto;  
  display: block;  
  width: 0px;  
  height: 0px;  
  min-width: 100%;  
  max-width: 100%;  
  min-height: 100%;  
  max-height: 100%;  
} 