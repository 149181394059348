
:root { 
    --color-arcadiaGreen: #0eff85; 
    --color-arcadiaGreen--hover: #0de578; 
    --color-lushGreen: #004037; 
    --color-cream: #f9f3e8; 
    --ease-arcadia: cubic-bezier(0.22,1,0.36,1); 
    --grid-columnCount: 4; 
    --grid-gutter: 1.6rem; 
    --grid-outerGutter: 1.6rem; 
    --spacing-80-xl: clamp(5.6rem,4.7619vw,8rem); 
    --spacing-40-md: clamp(2.4rem,2.381vw,4rem); 
} 

@media (min-width: 768px){ 
  :root { 
    --grid-columnCount: 8; 
    --grid-outerGutter: 2.4rem; 
  } 
}     

@media (min-width: 1024px){ 
  :root { 
    --grid-columnCount: 12; 
    --grid-gutter: 2.4rem; 
    --grid-outerGutter: 3.2rem; 
  } 
}     

@media (min-width: 1280px){ 
  :root { 
    --grid-outerGutter: 4rem;
  } 
}     

@media (min-width: 1440px){ 
  :root { 
    --grid-outerGutter: 4rem;
  } 
}     

@media (min-width: 1680px){ 
  :root { 
    --grid-outerGutter: 6.4rem;
  } 
}     

.Block_isCream__VRtaM { 
    background-color: var(--color-cream); 
    color: var(--color-lushGreen);
} 

.TextMedia_container__1OeME { 
    padding: var(--spacing-80-xl) 0;
} 



.Container_container__NvvWg { 
    display: grid; 
    grid-template-columns: repeat(var(--grid-columnCount),1fr); 
    align-items: start; 
    -moz-column-gap: var(--grid-gutter); 
    column-gap: var(--grid-gutter); 
    padding: 0 var(--grid-outerGutter); 
    width: 100%;
} 

@media (min-width: 1680px){ 
  .Container_container__NvvWg { 
    margin: 0 auto; 
    max-width: 168rem;
  } 
}     

.TextMedia_container__1OeME .TextMedia_wrapper__Uesgc  { 
    align-items: center;
} 

.TextMedia_content__jyQfX { 
    grid-column: 1/-1;
} 

@media (min-width: 768px){ 
  .TextMedia_content__jyQfX { 
    grid-column: 1/5;
  } 
}     

@media (min-width: 1024px){ 
  .TextMedia_content__jyQfX { 
    grid-column: 1/7; 
    padding-right: 4rem;
  } 
}     

@media (min-width: 1280px){ 
  .TextMedia_content__jyQfX { 
    padding-right: 6.4rem;
  } 
}     

.TextMedia_mediaWrapper__e1HP_ { 
    grid-column: 1/-1; 
    position: relative; 
    margin-top: 4rem; 
    overflow: hidden; 
    border-radius: .8rem; 
    visibility: hidden; 
    opacity: 0;
} 

@media (min-width: 768px){ 
  .TextMedia_mediaWrapper__e1HP_ { 
    grid-column: 5/9; 
    margin-top: 0;
  } 
}     

@media (min-width: 1024px){ 
  .TextMedia_mediaWrapper__e1HP_ { 
    grid-column: 7/13;
  } 
}     

.TextMedia_mediaWrapper__e1HP_:before { 
    content: ""; 
    display: block; 
    padding-bottom: calc(100% / 1.33);
} 

.TextMedia_eyebrow__y7WFh { 
    font-size: 1.2rem; 
    font-weight: 500; 
    line-height: 1.15; 
    letter-spacing: .03em; 
    text-transform: uppercase;
} 

@media (min-width: 768px){ 
  .TextMedia_eyebrow__y7WFh { 
    font-size: 1.4rem;
  } 
}     

.TextMedia_eyebrow__y7WFh { 
    display: block; 
    margin-bottom: var(--spacing-40-md); 
    visibility: hidden; 
    opacity: 0;
} 

h2 { 
    font-weight: unset; 
    font-size: unset; 
    margin: 0;
} 

.TitleAnimation_container__smkeR { 
    font-size: clamp(5.6rem,7.143vw,12rem); 
    position: relative;
} 

.TitleAnimation_container__smkeR,.TitleAnimation_container__smkeR.TitleAnimation_isExtraSmall__sS_xP { 
    font-family: var(--font-heading); 
    font-weight: 800; 
    line-height: .85; 
    letter-spacing: -.01em; 
    text-transform: uppercase;
} 

.TitleAnimation_container__smkeR.TitleAnimation_isExtraSmall__sS_xP { 
    font-size: clamp(4.8rem,4.76vw,8rem);
} 

.RichText_container__wB3Cy { 
    font-size: 1.6rem; 
    font-weight: 400; 
    line-height: 1.5; 
    display: flex; 
    flex-direction: column; 
    row-gap: 2rem;
} 

.TextMedia_copy__Ne6y6 { 
    font-size: 1.6rem; 
    font-weight: 400; 
    line-height: 1.5; 
    letter-spacing: .02em;
} 

@media (min-width: 1024px){ 
  .TextMedia_copy__Ne6y6 { 
    font-size: 1.8rem;
  } 
}     

@media (min-width: 1680px){ 
  .TextMedia_copy__Ne6y6 { 
    font-size: 2rem;
  } 
}     

.TextMedia_copy__Ne6y6 { 
    row-gap: 0; 
    margin-top: 1.6rem; 
    visibility: hidden; 
    opacity: 0;
} 

.MultiButton_multiButton__iqSU8 { 
    display: flex; 
    -moz-column-gap: 1.6rem; 
    column-gap: 1.6rem; 
    row-gap: 1.6rem; 
    flex-wrap: wrap;
} 

.TextMedia_multiButton__ISln4 { 
    margin-top: 4rem; 
    visibility: hidden; 
    opacity: 0;
} 

p { 
    font-weight: unset; 
    font-size: unset; 
    margin: 0;
} 

a { 
    color: inherit;
} 

a { 
    text-decoration: none;
} 

.BaseButton_baseButton__gki38 { 
    display: inline-flex; 
    align-items: center; 
    -moz-column-gap: .4rem; 
    column-gap: .4rem; 
    padding: 0 1.6rem; 
    height: 4.8rem; 
    font-size: 1.6rem; 
    font-weight: 700; 
    line-height: 1; 
    background-color: var(--color-arcadiaGreen); 
    border-radius: .8rem; 
    color: var(--color-lushGreen); 
    white-space: nowrap;
} 

@media (hover: hover){ 
  .BaseButton_baseButton__gki38 { 
    transition: .5s background-color var(--ease-arcadia),.5s color var(--ease-arcadia);
  } 

  .BaseButton_baseButton__gki38:hover { 
    background-color: var(--color-arcadiaGreen--hover);
  } 
}     

 

.TextMedia_media__OGUty { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    -o-object-fit: contain; 
    object-fit: contain;
} 

.TitleAnimation_title__liMEF { 
    visibility: hidden; 
    opacity: 0;
} 

.TitleAnimation_title__liMEF:first-child { 
    color: var(--color-arcadiaGreen);
} 

.TitleAnimation_title__liMEF:nth-child(2) { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%;
} 

.TitleAnimation_sr_only__GVx7j { 
    position: absolute; 
    width: 1px; 
    height: 1px; 
    padding: 0; 
    margin: -1px; 
    overflow: hidden; 
    clip: rect(0,0,0,0); 
    white-space: nowrap; 
    border-width: 0;
} 

svg { 
    display: block;
} 

.TitleAnimation_title__liMEF > div  { 
    overflow: hidden;
} 


/* These were inline style tags. Uses id+class to override almost everything */
#style-pFboy.style-pFboy {  
   translate: none;  
    rotate: none;  
    scale: none;  
    transform: translate(0px, 0px);  
    opacity: 1;  
    visibility: inherit;  
}  
#style-ZA5eE.style-ZA5eE {  
   opacity: 1;  
    visibility: inherit;  
}  
#style-d6ZMT.style-d6ZMT {  
   display: block;  
    text-align: start;  
    position: relative;  
    translate: none;  
    rotate: none;  
    scale: none;  
    transform: translate(0px, 0px);  
    will-change: transform;  
}  
#style-iyRbO.style-iyRbO {  
   display: block;  
    text-align: start;  
    position: relative;  
    translate: none;  
    rotate: none;  
    scale: none;  
    transform: translate(0px, 0px);  
    will-change: transform;  
}  
#style-ETIrS.style-ETIrS {  
   display: block;  
    text-align: start;  
    position: relative;  
    translate: none;  
    rotate: none;  
    scale: none;  
    transform: translate(0px, 0px);  
    will-change: transform;  
}  
#style-xRtzJ.style-xRtzJ {  
   opacity: 1;  
    visibility: inherit;  
}  
#style-s4HC5.style-s4HC5 {  
   display: block;  
    text-align: start;  
    position: relative;  
    translate: none;  
    rotate: none;  
    scale: none;  
    transform: translate(0px, 0px);  
    will-change: transform;  
}  
#style-lNXWo.style-lNXWo {  
   display: block;  
    text-align: start;  
    position: relative;  
    translate: none;  
    rotate: none;  
    scale: none;  
    transform: translate(0px, 0px);  
    will-change: transform;  
}  
#style-gJxrv.style-gJxrv {  
   display: block;  
    text-align: start;  
    position: relative;  
    translate: none;  
    rotate: none;  
    scale: none;  
    transform: translate(0px, 0px);  
    will-change: transform;  
}  
#style-STHt3.style-STHt3 {  
   opacity: 0.7;  
    visibility: inherit;  
}  
#style-ZG7s3.style-ZG7s3 {  
   translate: none;  
    rotate: none;  
    scale: none;  
    transform: translate(0px, 0px);  
    opacity: 1;  
    visibility: inherit;  
}  
#style-q2bJL.style-q2bJL {  
   translate: none;  
    rotate: none;  
    scale: none;  
    transform: translate(0px, 0px);  
    opacity: 1;  
    visibility: inherit;  
}  
#style-POjkF.style-POjkF {  
   translate: none;  
    rotate: none;  
    scale: none;  
    transform: translate(0px, 0px);  
    opacity: 1;  
    visibility: inherit;  
}  
#style-xcQ9s.style-xcQ9s {  
   color: transparent;  
}  
